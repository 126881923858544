<template>
  <div class=" mt-2">
    <b-card :title="$t('settings.team.titles.manage_teams')" class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                cle
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>
            <b-col cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="createTeamModal">
                  <span class="text-nowrap"> {{ $t('settings.team.buttons.create_new_team') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative p-2"
          :items="fetchTeams"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(index)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.index + 1 }}</strong>
              </span></b
            >
          </template>
          <!-- Column: Team Name -->
          <template #cell(TeamName)="data">
            <b v-if="
                  data.item.TeamName == 'Superuser' ||
                    data.item.TeamName == 'Onboarding' ||
                    data.item.TeamName == 'Operations' ||
                    data.item.TeamName == 'Trader' ||
                    data.item.TeamName == 'Viewing'
                "
              ><span class="font-weight-bold text-primary">
                <strong>{{
                  data.item.TeamName ? data.item.TeamName + '⁽ᵈ⁾' : "N/A"
                }}</strong>
              </span></b
            >

            <b v-else
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TeamName ? data.item.TeamName : "N/A"
                }}</strong>
              </span></b
            >

          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="teamDetail(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t('settings.team.buttons.details') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  data.item.TeamName != 'Superuser' &&
                    data.item.TeamName != 'Onboarding' &&
                    data.item.TeamName != 'Operations' &&
                    data.item.TeamName != 'Trader' &&
                    data.item.TeamName != 'Viewing'
                "
                @click="deleteTeam(data.item)"
              >
                <feather-icon icon="DeleteIcon" />
                <span class="align-middle ml-50">{{ $t('settings.team.buttons.delete_team') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            <span class="text-muted"
                >{{$t('general_text.showing')}} {{ dataMeta.from }} {{$t('general_text.to')}} {{ dataMeta.to }} {{$t('general_text.of')}}
                {{ dataMeta.of }} {{$t('general_text.entries')}}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      {{ /*permission create modal*/ }}
      <b-modal
        id="modal-prevent-closing"
        v-model="permCreateModal"
        :title="$t('settings.team.titles.create_new_team')"
        @hidden="resetPermCreateModal"
        scrollable
        size="lg"
        :ok-title="$t('settings.team.buttons.update')"
        :cancel-title="$t('settings.team.buttons.cancel')"
        @ok="createTeam"
      >
        <b-overlay :show="createPermloading" rounded="sm" no-fade>
          <b-container>
            <b-form>
              <b-row class="mt-2">
                <b-col cols="12" xl="12" md="12" style="margin-bottom: 6rem;">
                  <b-col cols="6" md="6" lg="6" sm="12">
                    <validation-observer ref="createTeamValidation">
                      <b-form>
                        <!-- teamname -->
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('settings.team.form.team_name')"
                            rules="required"
                          >
                            <b-form-input
                              id="teamname"
                              v-model="newTeam.name"
                              name="team-name"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('settings.team.form.team_name')"
                              autofocus
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-form>
                    </validation-observer>
                  </b-col>
                  <hr />

                  <b-col cols="4" md="4" lg="4" sm="12">
                    <b-form-input
                      v-model="filterModal"
                      class="d-inline-block mr-1"
                      cle
                      :placeholder="$t('general_text.search_table')"
                    />
                  </b-col>

                  <b-col
                    cols="12"
                    xl="12"
                    md="12"
                    sm="12"
                    class="bodyRow"
                    style="padding: 0.5rem 0;"
                  >
                    <b-table
                      hover
                      responsive
                      small
                      :filter="filterModal"
                      filter-debounce="250"
                      :filter-included-fields="filterModalOn"
                      fixed
                      :items="allCreateTeamPermissions"
                      class="position-relative"
                      :fields="detailPermColumns"
                      style="padding-left: 1rem;"
                    >
                      <template #cell(EntryType)="data">
                        <strong>{{ data.item.EntryType }}</strong>
                      </template>

                      <template #cell(ActionType)="data">
                        <b
                          ><strong>{{ data.item.ActionType }}</strong></b
                        >
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox
                          switch
                          size="sm"
                          v-model="data.item.value"
                        >
                        </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
          <template #overlay v-if="createPermloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" /> {{ $t('settings.team.messages.permission_creating_message') }} 
              </p>
            </div>
          </template>
        </b-overlay>
        {{/****END*** permissions create modal*/}}
      </b-modal>
      {{ /*Permissions detail && update modal*/ }}
      <b-modal
        id="modal-prevent-closing"
        v-model="permDetailModal"
        :title="selectedTeam"
        @hidden="resetPermDetailModal"
        scrollable
        size="lg"
        :ok-title="$t('settings.team.buttons.update')"
        :cancel-title="$t('settings.team.buttons.cancel')"
        @ok="editPerms"
      >
        <b-overlay :show="updatePermloading" rounded="sm" no-fade>
          <b-container>
            <b-form>
              <b-row class="mt-2">
                <b-col cols="12" xl="12" md="12" style="margin-bottom: 6rem;">
                  <b-col cols="12" md="4" lg="4" sm="12">
                    <b-form-input
                      v-model="filterModal"
                      class="d-inline-block mr-1"
                      cle
                      :placeholder="$t('general_text.search_table')"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    xl="12"
                    md="12"
                    sm="12"
                    class="bodyRow"
                    style="padding: 0.5rem 0;"
                  >
                    <b-table
                      hover
                      responsive
                      small
                      :filter="filterModal"
                      filter-debounce="250"
                      :filter-included-fields="filterModalOn"
                      fixed
                      :items="allUpdateTeamPermissions"
                      class="position-relative"
                      :fields="detailPermColumns"
                      style="padding-left: 1rem;"
                    >
                      <template #cell(EntryType)="data">
                        <strong>{{ data.item.EntryType }}</strong>
                      </template>

                      <template #cell(ActionType)="data">
                        <b
                          ><strong>{{ data.item.ActionType }}</strong></b
                        >
                      </template>

                      <template #cell()="data">
                        <b-form-checkbox
                          switch
                          size="sm"
                          v-model="data.item.value"
                        >
                        </b-form-checkbox>
                      </template>
                    </b-table>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
          <template #overlay v-if="updatePermloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" /> {{ $t('settings.team.messages.permission_updating_message') }} 
              </p>
            </div>
          </template>
        </b-overlay>
        {{/****END*** permissions detail update modal*/}}
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      fetchTeams: [],
      selectedTeam: null,
      permDetailModal: false,
      permCreateModal: false,
      rolePerms: [],
      allUpdateTeamPermissions: [],
      allCreateTeamPermissions: [],
      accessLevel: null,
      groupAccess: [],
      filterModal: null,
      filterModalOn: ["EntryType", "ActionType"],
      updatePermloading: false,
      createPermloading: false,
      newTeam: {
        name: null,
        permissions: [],
      },

      filterOn: ["TeamName"],
      tableColumns: [
        {
          key: "index",
          label: "",
        },
        {
          key: "TeamName",
          label: this.$t('settings.team.table.team_name'),
          sortable: true,
        },
        { key: "actions", label: this.$t('settings.team.table.actions'), sortable: false },
      ],

      detailPermColumns: [
        {
          key: "EntryType",
          label: this.$t('settings.team.table.entry_type'),
          sortable: false,
        },
        {
          key: "ActionType",
          label: this.$t('settings.team.table.action_type'),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
          class: "text-start",
        },
      ],

      perPage: localStorage.getItem("teamShow") ?? 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getTeams();
  },
  methods: {
    getTeams() {
      this.loading = true;
      store
        .dispatch("settings/fetchTeams", this.user)
        .then((res) => {
          this.fetchTeams = res.data;

          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    onRowSelected(item) {
      if (item.length > 0) {
        this.selectedTeam = item[0].TeamName;
        store
          .dispatch("settings/fetchTeamAndPermissions", {
            user: this.user,
            team: this.selectedTeam,
          })
          .then((res) => {
            this.accessLevel = res.data.accessLevel;
            if (res.data.groupAccess.length > 0) {
              res.data.groupAccess.forEach((perm) => {
                if (perm.AccessGroups.indexOf(this.accessLevel) != -1) {
                  perm.value = true;
                } else {
                  perm.value = false;
                }
              });
            }
            this.allUpdateTeamPermissions = res.data.groupAccess;
            this.permDetailModal = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    createTeamModal() {
      store
        .dispatch("settings/fetchPermissions", this.user)
        .then((res) => {
          if (res.data.length > 0) {
            res.data.forEach((perm) => {
              perm.value = false;
            });
            this.allCreateTeamPermissions = res.data;
            this.permCreateModal = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createTeam(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createTeamValidation.validate().then((success) => {
        if (success) {
          this.createPermloading = true;
          store
            .dispatch("settings/checkExistingTeam", {
              user: this.user,
              teamName: this.newTeam.name,
            })
            .then((res) => {
              if (res.data.existing == 1) {
                this.existingTeamMessage();
                this.createPermloading = false;
              } else {
                if (res.data.accessLevels.length > 0) {
                  let newAccessLevel = res.data.accessLevels.length + 1;
                  this.newTeam.permissions = this.allCreateTeamPermissions;
                  this.newTeam.permissions.forEach((perm) => {
                    if (perm.value == true) {
                      perm.AccessGroups =
                        perm.AccessGroups + "," + newAccessLevel;
                    }
                  });
                  store
                    .dispatch("settings/createNewTeam", {
                      user: this.user,
                      team: this.newTeam,
                      accessLevel: newAccessLevel,
                    })
                    .then((res) => {
                      this.createPermloading = false;
                      this.resetPermCreateModal();
                      this.getTeams();
                      this.createTeamToastMessage();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    teamDetail(team) {
      if (team.TeamName) {
        this.selectedTeam = team.TeamName;
        store
          .dispatch("settings/fetchTeamAndPermissions", {
            user: this.user,
            team: this.selectedTeam,
          })
          .then((res) => {
            this.accessLevel = res.data.accessLevel;
            if (res.data.groupAccess.length > 0) {
              res.data.groupAccess.forEach((perm) => {
                if (perm.AccessGroups.indexOf(this.accessLevel) != -1) {
                  perm.value = true;
                } else {
                  perm.value = false;
                }
              });
            }
            this.allUpdateTeamPermissions = res.data.groupAccess;
            this.permDetailModal = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    editPerms(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.allUpdateTeamPermissions.length > 0) {
        this.updatePermloading = true;
        this.allUpdateTeamPermissions.forEach((perm) => {
          if (perm.value == true) {
            if (perm.AccessGroups.indexOf(this.accessLevel) == -1) {
              perm.AccessGroups = perm.AccessGroups + "," + this.accessLevel;
            }
          } else {
            if (perm.AccessGroups.indexOf(this.accessLevel) != -1) {
              if (
                perm.AccessGroups.indexOf(this.accessLevel) ==
                perm.AccessGroups.length - 1
              ) {
                perm.AccessGroups = perm.AccessGroups.replace(
                  "," + this.accessLevel,
                  ""
                );
              } else {
                perm.AccessGroups = perm.AccessGroups.replace(
                  this.accessLevel + ",",
                  ""
                );
              }
            }
          }
        });

        store
          .dispatch("settings/updateGroupPermissions", {
            user: this.user,
            permissions: this.allUpdateTeamPermissions,
          })
          .then((res) => {
            if (res) {
              this.resetPermDetailModal();
              this.updatePermToastMessage("permissions");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    resetPermDetailModal() {
      this.selectedTeam = null;
      this.permDetailModal = false;
      this.updatePermloading = false;
      this.accessLevel = null;
      this.rolePerms = [];
      this.groupAccess = [];
      this.allUpdateTeamPermissions = [];
    },
    resetPermCreateModal() {
      this.permCreateModal = false;
      this.allCreateTeamPermissions = [];
      this.newTeam = {
        name: null,
        permissions: [],
      };
    },
    deleteTeam(team) {
      const self = this;
      this.$swal({
        title: this.$t('settings.team.messages.sure_delete_team') + ` ${team.TeamName}`,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t('settings.team.buttons.yes_delete_it'),
        cancelButtonText: this.$t('settings.team.buttons.cancel'),
        customClass: {
          confirmButton: "btn btn-success mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("settings/deleteTeamName", {
              user: this.user,
              team: team,
            })
            .then((res) => {
              if (res.data.notFound == 0) {
                self.getTeams();
                self.loading = false;
                self.$swal.fire({
                  icon: "success",
                  title: this.$t('settings.team.messages.team_deleted'),
                  text: this.$t('settings.team.titles.deleted'),
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                });
              } else if(res.data.notFound == 1) {
                self.loading = false;
                this.teamNotFoundMessage();
              } else {
                self.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    updatePermToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: "✔️ " + val + this.$t('settings.team.messages.val_update_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.team.messages.database_connection_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    existingTeamMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.team.messages.exist_team_name'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    teamNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.team.messages.team_not_found'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createTeamToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('settings.team.messages.team_perm_create_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
